import React, { useState, useEffect, lazy } from 'react';

import { Box } from '@mui/material';
import BaseLoader from '../../../components/BaseLoader';

import { useAuth } from '../../../lib/auth';
import { redirectTo } from '../../../utils/others';

const Login = lazy(() => import('../component/Login'));

function AuthCheck(props) {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [redirectURL, setRedirectURL] = useState(null);

  const { logout, verifyToken } = useAuth();

  useEffect(() => {
    async function fetchAuthDetails() {
      setLoading(true);
      try {
        const response = await verifyToken();
        if (response !== null) {
          handleSuccessfulLogin(response.data.redirect_url);
        }
      } catch (error) {
        const {
          response: { data = {} }
        } = error;
        const { status } = data;
        if (status === 401) logout();
      } finally {
        setLoading(false);
      }
    }

    fetchAuthDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirectURL && authenticated) {
      setLoading(true);
      setTimeout(() => {
        redirectTo(redirectURL);
      }, 1000);
    }
  }, [redirectURL, authenticated]);

  const handleSuccessfulLogin = (redirectURL) => {
    setRedirectURL(redirectURL);
    setAuthenticated(true);
  };

  return (
    <Box
      component="form"
      width={{ xs: '90%', lg: '400px' }}
      minHeight="230px"
      margin="0 auto"
      noValidate
      autoComplete="off"
    >
      {loading ? <BaseLoader /> : <Login {...props} onLoginSuccess={handleSuccessfulLogin} />}
    </Box>
  );
}

export default AuthCheck;
