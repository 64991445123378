import { loginWithEmailAndPassword, validateToken } from '../features/auth';
import storage from '../utils/storage';

function handleUserResponse(data) {
  const { token, user_details: user, redirect_url = null } = data;
  if (token) {
    storage.setToken(token);
    return { user, redirect_url };
  } else {
    throw new Error('Invalid Token!');
  }
}

async function verifyToken() {
  if (storage.getToken()) {
    const data = await validateToken(storage.getToken());
    return data;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response.data);
  return user;
}

function logoutFn() {
  storage.clearToken();
}

export const useAuth = () => {
  const authConfig = {
    verifyToken,
    login: loginFn,
    logout: logoutFn
  };

  return authConfig;
};
