import { Container } from '@mui/system';
import { CircularProgress } from '@mui/material';

function TheSplashScreen() {
  return (
    <Container
      maxWidth="100vw"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      disableGutters={true}
    >
      <CircularProgress />
    </Container>
  );
}

export default TheSplashScreen;
