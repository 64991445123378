import './App.css';

import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import TheLayout from './components/TheLayout';
import TheSplashScreen from './components/TheSplashScreen';
import AuthCheck from './features/auth/routes/AuthCheck';
import customTheme from './lib/theme';

import { getServerDetails } from './features/misc/api/serverDetails';

function App() {
  const [clientDetails, setClientDetails] = useState({});
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function setUserDetails() {
      setFetching(true);
      try {
        const { data } = await getServerDetails();
        const clientData = data.data;
        setClientDetails(clientData);
      } finally {
        setFetching(false);
      }
    }

    setUserDetails();
  }, []);

  const checkClientDetails = (clientDetails) => {
    return (
      typeof clientDetails === 'object' &&
      clientDetails.header_image &&
      typeof clientDetails.header_image === 'string'
    );
  };

  const isValidClientDetails = checkClientDetails(clientDetails);

  const isVisibleSplashScreen = fetching && !isValidClientDetails;

  const alertTitle = clientDetails.environment_header_1;
  const alertDescription = clientDetails.environment_header_2;

  const activeDirectory = clientDetails.active_directory_enable
    ? { label: clientDetails.active_directory_label, link: clientDetails.active_directory_link }
    : null;

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {isVisibleSplashScreen ? (
        <TheSplashScreen />
      ) : (
        <TheLayout details={clientDetails}>
          <AuthCheck
            isRecaptchaEnabled={clientDetails?.recaptha_enable ?? false}
            alert={{ title: alertTitle, desc: alertDescription }}
            activeDirectory={activeDirectory}
          />
        </TheLayout>
      )}
    </ThemeProvider>
  );
}

export default App;
