import { axios } from '../../../lib/axios';

export const validateToken = (authToken) => {
  return axios.post(
    'token_verify',
    {},
    {
      headers: {
        'Auth-Token': authToken
      }
    }
  );
};
