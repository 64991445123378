/**
 * Process the input string and replace URLs using a callback function.
 *
 * @param {string} inputStr - The string that may contain URLs.
 * @param {Function} callback - A function that gets passed each matched URL and returns a React element as a replacement.
 * @returns {Array} - An array containing strings and React elements.
 */
export function processStringWithUrls(inputStr, callback) {
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi;
  let lastIndex = 0;
  let index = 0;
  const result = [];

  inputStr.replace(urlRegex, (match, _, offset) => {
    index++;
    // Add the previous string part, if any
    if (offset > lastIndex) {
      result.push(inputStr.slice(lastIndex, offset));
    }

    // Add the replacement for the URL (a React element in this case)
    result.push(callback(match, index));

    // Update the last index for the next iteration
    lastIndex = offset + match.length;
  });

  // Add the remaining string part, if any
  if (lastIndex < inputStr.length) {
    result.push(inputStr.slice(lastIndex));
  }

  return result;
}

export const redirectTo = (URL) => {
  window.location.replace(URL);
};
