import { Box, Container } from '@mui/system';
import { Typography, Link, useTheme, useMediaQuery } from '@mui/material';

import { processStringWithUrls } from '../utils/others';

import Styles from './TheLayout.module.css';

function Layout({ details, children }) {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('xs'));

  const headerImage = details.header_image;
  const clientLogo = details.server_logo;
  const clientName = details.servername;
  const backgroundImage = details.background_image;

  const footerImage = details.footer_image;

  let footerTextFirstLine = details?.footer_line_1 ?? '';
  if (isLgScreen) {
    footerTextFirstLine = processStringWithUrls(footerTextFirstLine, (matchedURL, index) => {
      return (
        <Link
          key={index}
          href={matchedURL}
          target="_blank"
          rel="noopener noreferrer"
          color="textSecondary"
          underline="hover"
        >
          {matchedURL}
        </Link>
      );
    });
  }
  const footerTextSecondLine = details.footer_line_2 ?? '';

  return (
    <Container
      className={Styles['main-container']}
      sx={{ backgroundImage: { xs: 'none', lg: `url(${backgroundImage})` } }}
      maxWidth="100vw"
      disableGutters={true}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            lg: '85%', // On 1280px width, make it 80%
            xl: '75%', // On 1366px width, make it 70%
            xxl: '65%' // On 1920px width and above, make it 60%,
          },
          height: {
            xs: '100%',
            lg: 'auto'
          },
          mt: { xs: 10, lg: 0 }
        }}
      >
        <Box sx={{ textAlign: 'right', display: { xs: 'none', lg: 'block' } }}>
          {headerImage && (
            <Box
              component="img"
              src={headerImage} // Replace with your logo path
              alt="Amatia Logo"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: '#fff',
            mb: { xs: 0, lg: 4 }
          }}
          borderRadius={1}
        >
          <Box
            sx={{
              position: { xs: 'relative', lg: 'static' },
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              flexGrow: 1
            }}
          >
            <Box
              sx={{
                position: { xs: 'absolute', lg: 'static' },
                width: { xs: '100%', lg: '50%' },
                height: { xs: '200px', lg: 'auto' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                component="img"
                src={clientLogo} // Replace with your logo path
                alt={clientName}
                sx={{
                  maxWidth: '200px', // Adjust width as needed
                  height: 'auto'
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', lg: '50%' },
                py: { xs: 0, lg: 3 },
                px: { xs: 0, lg: 4 },
                mt: { xs: 20, lg: 0 }
              }}
            >
              {children}
            </Box>
          </Box>
          {footerImage && (
            <Box
              component="img"
              src={footerImage} // Replace with your logo path
              alt="Footer Image"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: { xs: 'none', lg: 'block' }
              }}
            />
          )}
        </Box>
        {footerTextFirstLine && footerTextSecondLine && (
          <Box sx={{ textAlign: 'right', display: { xs: 'none', lg: 'block' } }}>
            {/* Powered By Text */}
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                fontSize: '0.75rem',
                lineHeight: 1.5 // Provides a bit of spacing between lines
              }}
            >
              {footerTextFirstLine}
              <br />
              {footerTextSecondLine}
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Layout;
