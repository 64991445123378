// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      SignIn: 'Sign in',
      SignInMessage: 'to continue to Amatia',
      UserName: 'User name',
      UserNameRequired: 'Enter an User name',
      Password: 'Password',
      PasswordRequired: 'Enter a password',
      ShowPassword: 'Show Password',
      Next: 'Next',
      Login: 'Login',
      Welcome: 'Welcome'
      // Add more key-value pairs for translation
    }
  },
  es: {
    translation: {
      SignIn: 'Inicia sesión',
      SignInMessage: ' para continuar en Amatia.',
      UserName: 'Nombre de usuario',
      UserNameRequired: 'Ingresa un nombre de usuario',
      Password: 'Contraseña',
      PasswordRequired: 'Ingresa una contraseña',
      ShowPassword: 'Mostrar contraseña',
      Next: 'Siguiente',
      Login: 'Iniciar sesión',
      Welcome: 'Bienvenido',
      Hello: 'Hola',
      BackToDashboard: 'Volver al panel',
      MessageCenter: 'Centro de mensajes',
      Save: 'Ahorrar',
      Cancel: 'Cancelar',
      Reset: 'Reiniciar',
      FormSavedMessage: 'Formulario guardado exitosamente'
      // Add more key-value pairs for translation
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es', // Set the initial language
  interpolation: {
    escapeValue: false // React already escapes values, so this is not needed
  }
});

export default i18n;
