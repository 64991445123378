import Axios from 'axios';

import { API_URL } from '../config';

// Function to determine the base URL dynamically
const getApiBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    // In production, construct the URL dynamically
    const { protocol, hostname } = window.location;
    return `${protocol}//${hostname}/amatia`;
  } else {
    // In local development, read from .env.local
    return API_URL;
  }
};

export const axios = Axios.create({
  baseURL: `${getApiBaseUrl()}/api/auth_service/`
});

export const clientAxios = Axios.create({
  baseURL: `${getApiBaseUrl()}/api/`
});
